<template>
  <div class="price-container mb-2">
    <div v-for="(price, index) in priceInfo" :key="index">
      <div v-if="price?.tier == 'IO' && checkZeroPrice(price)" class="price-info">
        <b-row>
          <b-col cols="5" sm="5" md="5" lg="5" xl="5" xxl=""><span><Strong>Single: </Strong></span></b-col>
          <b-col cols="4" sm="4" md="5" lg="5" xl="5" class="price-detail"><span>${{price?.price_male_nons}}</span></b-col>
          <b-col cols="3" sm="3" md="2" lg="2" xl="2" class="price-detail" v-if="price?.tier  == selectedTier">
            <div v-if="!items.hide_add_to_cart">
              <span v-if="isPlanOnCart" class="tick-icon"><img :src="checkIcon"  class="check-icon" v-b-popover.hover.top="'Plan Added To Cart'"/></span>
              <span v-else @click="addPlanToCart()" class="cart-icon" v-b-popover.hover.top="'Add To Cart'">
               <img :src="cartIcon" />
             </span>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="price?.tier == 'IS' && checkZeroPrice(price)" class="price-info">
        <b-row>
          <b-col cols="5" sm="5" md="5" lg="5" xl="5"><span><Strong>Single + Spouse: </Strong></span></b-col>
          <b-col cols="4" sm="4" md="5" lg="5" xl="5" class="price-detail"><span>${{price?.price_male_nons}}</span></b-col>
          <b-col cols="3" sm="3" md="2" lg="2" xl="2"  class="price-detail" v-if="price?.tier  == selectedTier">
            <div v-if="!items.hide_add_to_cart">
              <span v-if="isPlanOnCart" class="tick-icon">
                <img :src="checkIcon"  class="check-icon" v-b-popover.hover.top="'Plan Added To Cart'">
              </span>
              <span v-else @click="addPlanToCart()" class="cart-icon" v-b-popover.hover.top="'Add To Cart'">
                 <img :src="cartIcon" />
               </span>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="price?.tier == 'IC' && checkZeroPrice(price)" class="price-info">
        <b-row>
          <b-col cols="5" sm="5" md="5" lg="5" xl="5"><span><Strong>Single + Children: </Strong></span></b-col>
          <b-col cols="4" sm="4" md="5" lg="5" xl="5" class="price-detail"><span>${{price?.price_male_nons}}</span></b-col>
          <b-col cols="3" sm="3" md="2" lg="2" xl="2"   class="price-detail" v-if="price?.tier  == selectedTier">
            <div v-if="!items.hide_add_to_cart">
              <span v-if="isPlanOnCart" class="tick-icon">
                <img :src="checkIcon"  class="check-icon" v-b-popover.hover.top="'Plan Added To Cart'">
              </span>
              <span v-else @click="addPlanToCart()" class="cart-icon" v-b-popover.hover.top="'Add To Cart'">
                 <img :src="cartIcon" />
               </span>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="price?.tier == 'IF' && checkZeroPrice(price)" class="price-info">
        <b-row>
          <b-col cols="5" sm="5" md="5" lg="5" xl="5"><span><Strong>Family: </Strong></span></b-col>
          <b-col cols="4" sm="4" md="5" lg="5" xl="5" class="price-detail"><span>${{price?.price_male_nons}}</span></b-col>
          <b-col cols="3" sm="3" md="2" lg="2" xl="2" class="price-detail" v-if="price?.tier  == selectedTier">
            <div v-if="!items.hide_add_to_cart">
              <span v-if="isPlanOnCart" class="tick-icon">
                <img :src="checkIcon"  class="check-icon" v-b-popover.hover.top="'Plan Added To Cart'">
              </span>
              <span v-else @click="addPlanToCart()" class="cart-icon" v-b-popover.hover.top="'Add To Cart'">
                 <img :src="cartIcon" />
               </span>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";

export default {
  name: 'PriceDetailInfo',
  props: {
    priceInfo: {
      type: Array,
      require: true
    },
    isPlanOnCart: {
      type: Boolean
    },
    items: {
      type: Object
    }
  },
  data () {
    return {
      selectedTier: "",
      tempId: "",
      checkPrudential: false,
      localIsPlanOnCart: false,
      cartIcon: require('../assets/images/empty-cart.png'),
      checkIcon: require('../assets/images/cart-added.png')
    }
  },
  mounted() {
    let app = this
    app.selectedTier = window.localStorage.getItem('selectedTier');
    app.tempId = window.localStorage.getItem('tempId');
  },
  methods: {
    addPlanToCart () {
      let app = this
      app.$emit('getPlanPricing', app.items)
    },
    checkZeroPrice (price) {
      if (parseInt(price?.price_male_nons) > 0 ) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
<style scoped lang="scss">
.price-container {
  border-top: 1px solid #f2f2f2;
  padding: 10px 0px;
  .price-info {
    padding: 3px 0px;
    .price-detail {
      text-align: right;
      .cart-icon {
        cursor: pointer;
        position: relative;
        right: 13px;
        top: -4.1px;
        width: 22px;
        @media (max-width:1199px) {
          right: 3px;
        }
      }
      .tick-icon {
        position: relative;

        top: 0px;
      }
      .check-icon {
        width: 22px;
        position: absolute;
        right:13px;
        top: -4.1px;
        @media (max-width:1199px) {
          right: 3px;
        }
      }
    }
  }
}
</style>

