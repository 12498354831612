<template>
  <section class="innerpage-wrap">
    <div class="container" ref="health_info">
      <div class="split-title">
        <h1 class="brand-color">Primary Health Questions</h1>
      </div>
      <div class="enrollmentForm healthQues card-block">

        <vue-scroll :ops="quesScroll">
          <ul >
            <li v-for="(quest,index) in questions" v-bind:key="index">
              <div class="split">
                {{quest.question_text | removeHtmlTags}}
                <div class="text-end radioBtn">
                  <div class="form-check form-check-inline mt-3">
                    <input class="form-check-input" type="radio" name="" id="inlineRadio1"  v-model="yn[quest.question_id]" value="1" @click="setAns(quest.question_id, 'yes')">
                    <label class="form-check-label" for="inlineRadio1">Yes</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input v-if="yn[quest.question_id] != null" class="form-check-input" type="radio" name="" id="inlineRadio2"  v-model="yn[quest.question_id]"  value="0" @click="setAns(quest.question_id, 'no')">
                    <input v-else class="form-check-input" type="radio" name="" id="inlineRadio2" checked  value="0" @click="setAns(quest.question_id, 'no')">
                    <label class="form-check-label" for="inlineRadio2">No</label>
                  </div>
                </div>
              </div>
              <div v-if="quest.question_id == 145 || quest.question_id == 146 || quest.question_id == 147">
                <div class="answer-field row" v-if="yesNoAns['q'+quest.question_id] > 0 && quest.question_id != 145">

                  <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mb-3">
                    <label>Condition</label>
                    <input class="custom-input" placeholder="Enter condition" type="text" name="condition" v-model="answers[quest.question_id+'condition1']">
                    <div class="error_message" v-if="formErrorFlag == true">{{formErrors['condition_questions.'+index+'.conditions.0.condition_message']}}</div>
                  </div>
                  <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mb-3">
                    <label>Status</label>
                    <input class="custom-input" placeholder="Enter status" type="text" name="status" v-model="answers[quest.question_id+'status1']">
                    <div class="error_message" v-if="formErrorFlag == true">{{formErrors['condition_questions.'+index+'.conditions.0.status_message']}}</div>
                  </div>
                  <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mb-3">
                    <label>Last Seen By Physician</label>
                    <v-date-picker
                        v-model="answers[quest.question_id+'last_seen_by_physician1']"
                        v-on:keyup="eventDob(quest.question_id, answers[quest.question_id+'last_seen_by_physician1'], 'last_seen_by_physician1')"
                        :popover="{ visibility: 'click' }"
                        :max-date="new Date()"
                        @input="removeError(); convertLastPhysicianDateFormat(quest.question_id);"
                        mode="date">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                            class="custom-input"
                            placeholder="mm/dd/yyyy"
                            size=10 maxlength=10
                            :value="inputValue.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                            v-on="inputEvents"
                        />
                      </template>
                    </v-date-picker>
                    <div class="error_message" v-if="formErrorFlag == true">{{formErrors['condition_questions.'+index+'.conditions.0.last_seen_by_physician_message']}}</div>
                  </div>
                  <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mb-3">
                    <label>Date of Recovery</label>
                    <v-date-picker
                        v-model="answers[quest.question_id+'date_of_recovery1']"
                        v-on:keyup="eventDob(quest.question_id, answers[quest.question_id+'date_of_recovery1'], 'date_of_recovery1')"
                        :popover="{ visibility: 'click' }"
                        :max-date="new Date()"
                        @input="removeError(); convertRecoveryDateFormat(quest.question_id);"
                        mode="date">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                            class="custom-input"
                            placeholder="mm/dd/yyyy"
                            size=10 maxlength=10
                            :value="inputValue.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                            v-on="inputEvents"
                        />
                      </template>
                    </v-date-picker>
                    <div class="error_message" v-if="formErrorFlag == true">{{formErrors['condition_questions.'+index+'.conditions.0.date_of_recovery_message']}}</div>
                  </div>
                  <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mb-3">
                    <label>Physician Name</label>
                    <input class="custom-input" placeholder="Enter physician name" type="text" name="physician_name" v-model="answers[quest.question_id+'physician_name1']">
                    <div class="error_message" v-if="formErrorFlag == true">{{formErrors['condition_questions.'+index+'.conditions.0.physician_name_message']}}</div>
                  </div>
                  <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mb-3">
                    <label>Address</label>
                    <input class="custom-input" placeholder="Enter address" type="text" name="address" v-model="answers[quest.question_id+'address1']">
                    <div class="error_message" v-if="formErrorFlag == true">{{formErrors['condition_questions.'+index+'.conditions.0.address_message']}}</div>
                  </div>
                  <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mb-3">
                    <label>Phone</label>
                    <input class="custom-input" placeholder="Enter phone" type="text" name="phone" v-model="answers[quest.question_id+'phone1']">
                    <div class="error_message" v-if="formErrorFlag == true">{{formErrors['condition_questions.'+index+'.conditions.0.phone_message']}}</div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="answer-field row" v-if="yesNoAns['q'+quest.question_id] > 0 ">

                  <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mt-3">
                    <label>Condition/Diseases/Diagnosis/Treatment</label>
                    <input class="custom-input"
                           @input="removeError();"
                           :class="{'form-control is-invalid': formErrors['condition_questions.'+index+'.conditions.0.health_condition_message']}"
                           placeholder="Enter health condition" type="text" name="health_condition" v-model="answers[quest.question_id+'health_condition1']">
                    <div class="error_message" v-if="formErrorFlag == true">{{formErrors['condition_questions.'+index+'.conditions.0.health_condition_message']}}</div>
                  </div>
                  <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mt-3">
                    <label>Date of Onset</label>
                    <v-date-picker
                        v-model="answers[quest.question_id+'date_of_onset1']"
                        v-on:keyup="eventDob(quest.question_id, answers[quest.question_id+'date_of_onset1'], 'date_of_onset1')"
                        :popover="{ visibility: 'click' }"
                        :max-date="new Date()"
                        @input="removeError(); convertTreatmentDateFormat(quest.question_id);"
                        mode="date">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                            class="custom-input"
                            placeholder="mm/dd/yyyy"
                            size=10 maxlength=10
                            :class="{'invalid': formErrors['condition_questions.'+index+'.conditions.0.date_of_onset_message']}"
                            :value="inputValue.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                            v-on="inputEvents"
                        />
                      </template>
                    </v-date-picker>
                    <div class="error_message" v-if="formErrorFlag == true">{{formErrors['condition_questions.'+index+'.conditions.0.date_of_onset_message']}}</div>
                  </div>
                  <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mt-3">
                    <label>Date of Recovery</label>
                    <v-date-picker
                        v-model="answers[quest.question_id+'date_of_recovery1']"
                        v-on:keyup="eventDob(quest.question_id, answers[quest.question_id+'date_of_recovery1'], 'date_of_recovery1')"
                        :popover="{ visibility: 'click' }"
                        :max-date="new Date()"
                        @input="removeError(); convertRecoveryDateFormat(quest.question_id);"
                        mode="date">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                            class="custom-input"
                            placeholder="mm/dd/yyyy"
                            size=10 maxlength=10
                            :class="{'invalid': formErrors['condition_questions.'+index+'.conditions.0.date_of_onset_message']}"
                            :value="inputValue.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                            v-on="inputEvents"
                        />
                      </template>
                    </v-date-picker>
                    <div class="error_message" v-if="formErrorFlag == true">{{formErrors['condition_questions.'+index+'.conditions.0.date_of_recovery_message']}}</div>
                  </div>
                  <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mt-3">
                    <label>Current Treatment</label>
                    <b-form-select class="custom-input"
                            @input="removeError();"
                            :class="{'invalid': formErrors['condition_questions.'+index+'.conditions.0.is_treatment_message']}"
                            name="is_treatment"
                            :options="currentTreatmentOption"
                            v-model="answers[quest.question_id+'is_treatment1']">
                    </b-form-select>
                    <div class="error_message" v-if="formErrorFlag == true">{{formErrors['condition_questions.'+index+'.conditions.0.is_treatment_message']}}</div>
                  </div>
                  <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mt-3">
                    <label>Taking Medication</label>
                    <b-form-select class="custom-input"
                            @input="removeError();"
                             :options="takingMedicalOption"
                            :class="{'invalid': formErrors['condition_questions.'+index+'.conditions.0.is_medicate_message']}"
                            name="is_medicate" v-model="answers[quest.question_id+'is_medicate1']">
                    </b-form-select>
                    <div class="error_message" v-if="formErrorFlag == true">{{formErrors['condition_questions.'+index+'.conditions.0.is_medicate_message']}}</div>
                  </div>
                  <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 mt-3">
                    <label>Date Last Seen By Physician</label>
                    <v-date-picker
                        v-model="answers[quest.question_id+'d_last_seen1']"
                        v-on:keyup="eventDob(quest.question_id, answers[quest.question_id+'d_last_seen1'], 'd_last_seen1')"
                        :popover="{ visibility: 'click' }"
                        :max-date="new Date()"
                        @input="removeError(); convertPhysicianDateFormat(quest.question_id);"
                        mode="date">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                            class="custom-input"
                            placeholder="mm/dd/yyyy"
                            size=10 maxlength=10
                            :class="{'invalid': formErrors['condition_questions.'+index+'.conditions.0.d_last_seen_message']}"
                            :value="inputValue.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                            v-on="inputEvents"
                        />
                      </template>
                    </v-date-picker>
                    <div class="error_message" v-if="formErrorFlag == true">{{formErrors['condition_questions.'+index+'.conditions.0.d_last_seen_message']}}</div>
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-12 mt-3">
                    <label>Remaining Symptoms of Problems? Surgery or Hospitalization</label>
                    <input class="custom-input" type="text"
                           @input="removeError();"
                           :class="{'form-control is-invalid': formErrors['condition_questions.'+index+'.conditions.0.symptoms_message']}"
                           placeholder="Enter remaining problems" name="symptoms" value="" v-model="answers[quest.question_id+'symptoms1']">
                    <div class="error_message" v-if="formErrorFlag == true">{{formErrors['condition_questions.'+index+'.conditions.0.symptoms_message']}}</div>
                  </div>
                </div>
              </div>
              <!-- <div class="container">
    <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8" v-if="yesNoAns['q'+quest.question_id] > 0 ">
        <table class="table" cellspacing="0" style="border-collapse: unset !important;" >
            <tr>
                <td>Condition/Diseases/Diagnosis/Treatment</td>
                <td>
                  <input class="custom-input" type="text" name="health_condition" v-model="answers[quest.question_id+'health_condition1']">
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['condition_questions.'+index+'.conditions.0.health_condition_message']}}</div>
                </td>
            </tr>
            <tr>
                <td>Date of Onset</td>
                <td>
                <date-picker
                v-model="answers[quest.question_id+'date_of_onset1']"
                v-on:keyup="eventDob(quest.question_id, answers[quest.question_id+'date_of_onset1'], 'date_of_onset1')"
                input-class="custom-input"
                placeholder="mm/dd/yyyy"
                format="MM/DD/YYYY"
                value-type="format"
                type="date" ></date-picker>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['condition_questions.'+index+'.conditions.0.date_of_onset_message']}}</div>
                </td>
            </tr>
            <tr>
                <td>Date of Recovery</td>
                <td>
                <date-picker
                v-model="answers[quest.question_id+'date_of_recovery1']"
                v-on:keyup="eventDob(quest.question_id, answers[quest.question_id+'date_of_recovery1'], 'date_of_recovery1')"
                input-class="custom-input"
                placeholder="mm/dd/yyyy"
                format="MM/DD/YYYY"
                value-type="format"
                type="date" ></date-picker>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['condition_questions.'+index+'.conditions.0.date_of_recovery_message']}}</div>
                </td>
            </tr>
            <tr>
                <td>Current Treatment</td>
                <td>
                  <select class="custom-input" name="is_treatment" v-model="answers[quest.question_id+'is_treatment1']">
                    <option value="" selected disabled>select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['condition_questions.'+index+'.conditions.0.is_treatment_message']}}</div>
                </td>
            </tr>
            <tr>
                <td>Taking Medication</td>
                <td>
                  <select class="custom-input" name="is_medicate" v-model="answers[quest.question_id+'is_medicate1']">
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['condition_questions.'+index+'.conditions.0.is_medicate_message']}}</div>
                </td>
            </tr>
            <tr>
                <td>Date Last Seen By Physician</td>
                <td>
                <date-picker
                v-on:keyup="eventDob(quest.question_id, answers[quest.question_id+'d_last_seen1'], 'd_last_seen1')"
                v-model="answers[quest.question_id+'d_last_seen1']"
                input-class="custom-input"
                placeholder="mm-dd-yyyy"
                format="MM/DD/YYYY"
                type="date"
                value-type="format"></date-picker>
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['condition_questions.'+index+'.conditions.0.d_last_seen_message']}}</div>
                </td>
            </tr>
            <tr>
                <td>Remaining Symptoms of Problems? Surgery or Hospitalization</td>
                <td>
                  <input class="custom-input" type="text" name="symptoms" value="" v-model="answers[quest.question_id+'symptoms1']">
                  <div class="error_message" v-if="formErrorFlag == true">{{formErrors['condition_questions.'+index+'.conditions.0.symptoms_message']}}</div>
                </td>
            </tr>
        </table>
    </div>
              </div> -->
            </li>

          </ul>


          <ul v-if="medQuestion.question_id > 0">
            <li>
              <div class="split">
                {{medQuestion['question_text']}}
                <div class="text-end">
                  <div class="form-check form-check-inline mt-3">
                    <input class="form-check-input" type="radio" id="inlineRadio1"  v-model="yn[medQuestion.question_id]" value="1" @click="setAns(medQuestion.question_id, 'yes')">
                    <label class="form-check-label" for="inlineRadio1">Yes</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input v-if="yn[medQuestion.question_id]!=null" class="form-check-input" type="radio" id="inlineRadio2" v-model="yn[medQuestion.question_id]" value="0" @click="setAns(medQuestion.question_id, 'no')">
                    <input v-else class="form-check-input" type="radio" id="inlineRadio2" checked value="0" @click="setAns(medQuestion.question_id, 'no')">
                    <label class="form-check-label" for="inlineRadio2">No</label>
                  </div>
                </div>
              </div>
            </li>
            <div class="answer-field row" v-if="yesNoAns['q'+medQuestion.question_id] == 1">
              <div class="row"  v-for="(med, medIndex) in medicationLists" :key="medIndex">
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
                  <label>Medication/Rx injection</label>
                  <input
                      class="custom-input"
                      @input="removeError();"
                      :class="{'form-control is-invalid': formErrors[`medication_question.medications.${medIndex}.medication_message`]}"
                      placeholder="Enter Medication"
                      type="text"
                      name="medication"
                      v-model="med.medication"
                  >
                  <span class="error_message" v-if="formErrorFlag == true">{{formErrors[`medication_question.medications.${medIndex}.medication_message`]}}</span>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
                  <label>Dosage</label>
                  <input
                      class="custom-input"
                      @input="removeError();"
                      :class="{'form-control is-invalid': formErrors[`medication_question.medications.${medIndex}.dosage_message`]}"
                      placeholder="Enter Dosage"
                      type="text"
                      name="symptoms"
                      v-model="med.dosage" >
                  <span class="error_message" v-if="formErrorFlag == true">{{formErrors[`medication_question.medications.${medIndex}.dosage_message`]}}</span>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
                  <label>Medical Condition</label>
                  <input
                      class="custom-input"
                      @input="removeError();"
                      :class="{'form-control is-invalid': formErrors[`medication_question.medications.${medIndex}.medical_condition_message`]}"
                      placeholder="Enter Medical Condition"
                      type="text"
                      name="symptoms"
                      v-model="med.medical_condition"
                  >
                  <span class="error_message" v-if="formErrorFlag == true">{{formErrors[`medication_question.medications.${medIndex}.medical_condition_message`]}}</span>

                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
                  <div class="remove-container" v-if="!(medicationLists.length <= 1)">
                  <span class="remove-medication"
                        @click="removeMedication(medIndex)"
                  ><i class="far fa-times-circle" aria-hidden="true"></i> Remove </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                  <b-button class="add-medication-btn"  @click="addMedication"> + Add Medication</b-button>
                </div>
              </div>
            </div>
          </ul>
        </vue-scroll>
        <div class="additional_notes">
          <label><b>Additional Notes</b></label>
          <textarea class="notes custom-input" v-model="notes" rows="4"></textarea>
        </div>
      </div>

      <b-modal v-bind:hide-footer="true" id="code-send" centered size="md" >
        <template #modal-header>
          <div class="popUp-header" style="border-bottom: 0px !important">
            <div  v-if="saveSuccess">
              <h4 style="color: green"><b>Information Saved!!!</b> Check your email to continue</h4>
            </div>
            <div v-else>
              <h4>Please enter the alternate email you want to send Enrollment code to?</h4>
            </div>
            <b-button variant="close-btn" @click="$bvModal.hide('code-send')">
              <i class="fas fa-times"></i>
            </b-button>
          </div>
        </template>
        <b-container fluid>
          <div v-if="saveSuccess==false" class="input_box client_email">
            <input type="text" class="custom-input" v-model="email" value="" />
            <div class="error_message" v-if="formErrorFlag == true">{{formErrors['email_message']}}</div>
          </div>
          <div class="popUp-details text-center">
            <div v-if="saveSuccess" class="action-btn text-center mt-4">
              <b-button style="width:100%;"  class="button-brand mx-1" @click="$bvModal.hide('code-send')" data-dismiss="modal">Done</b-button>
            </div>
            <div v-else class="action-btn text-center mt-4">
              <b-button style="width:40%;"  class="dark-btn mx-1" @click="$bvModal.hide('code-send')">Back</b-button>
              <b-button style="width:40%;"  class="button-brand mx-1" @click="saveAndContinueEnrollment" data-dismiss="modal">Send Code</b-button>
            </div>
          </div>
        </b-container>

      </b-modal>
      <div class="action-btn split-title mt-5">
        <router-link to="" @click.native="navigatePrevious" class="dark-btn">Back</router-link>
        <div class="action-next">
          <router-link to="" @click.native="saveEnrollment"  class="button-green">Save & Continue Later</router-link>
          <router-link to="" @click.native="submitApi" class="button-brand">Next</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import html2canvas from 'html2canvas';
export default {
  name: "HealthQuestions",
  data: function () {
    return {
      tempId: '',
      visible: false,
      questions: [],
      medQuestion: [],
      yn: [],
      yesNoAns: [],
      answers: [],
      medAnswers: [],
      notes: '',
      finalDataCondAns: [],
      finalDataMedAns: [],
      formErrors: [],
      formErrorFlag: false,
      savePopup: false,
      email: '',
      saveSuccess: false,
      currentTreatmentOption: [
        { value: 'undefined', text: 'please select'},
        { value: '1', text: 'Yes' },
        { value: '0', text: 'No'}
      ],
      takingMedicalOption: [
        { value: 'undefined', text: 'please select'},
        { value: '1', text: 'Yes' },
        { value: '0', text: 'No'}
      ],
      medicationLists: []
    }
  },
  mounted() {
    let app = this;
    app.tempId = window.localStorage.getItem('tempId');
    app.enroll_type = window.localStorage.getItem('enroll_type');
    app.scrollTop();
    app.getQuestions();

    if(app.enroll_type == 'existing-member' && !window.localStorage.getItem('health_track')){
      app.fetchExistingInfo();
    }
    else{
    app.fetchInfo();
    }
    app.renderEmptyMedication();
  },
  methods: {
    checkValue: function(str, max) {
      if (str.charAt(0) !== '0' || str == '00') {
        var num = parseInt(str);
        if (isNaN(num) || num <= 0 || num > max) num = 1;
        str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
      }
      return str;
    },
    eventDob: function(questionid,input,name) {
      let app = this;
      // console.log(questionid);
      // console.log(name);
      // var input = .value;
      if (/\D\/$/.test(input)) input = input.substr(0, input.length - 1);
      var values = input.split('/').map(function(v) {
        return v.replace(/\D/g, '')
      });
      if (values[0]) values[0] = app.checkValue(values[0], 12);
      if (values[1]) values[1] = app.checkValue(values[1], 31);
      var output = values.map(function(v, i) {
        return v.length == 2 && i < 2 ? v + '/' : v;
      });
      // app.answers[quest.question_id+'date_of_onset1']
      // console.log(app.answers[questionid+name]);
      app.answers[questionid+name] = output.join('').substr(0, 10);
      // console.log(app.answers[questionid+name]);
    },
    getQuestions: function() {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-health-questions-primary-health-info', {
        params: {
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.questions = response.data.data.result.codition_questions;
              app.medQuestion['question_id'] = response.data.data.result.medication_question.question_id;
              app.medQuestion['question_text'] = response.data.data.result.medication_question.question_text;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    fetchInfo: function() {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-temp-info', {
        params: {
          enrollment_id: app.tempId,
          column: 'health_info',
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.notes=response.data.data.result.notes;
              response.data.data.result.condition_questions.forEach(function(item) {
                if (item.yes_no_flag == 1) {
                  app.yesNoAns['q'+item.question_id] = 1;
                  app.yn[item.question_id] = 1;
                  console.log('This is what I need'+app.yesNoAns['q'+item.question_id]);
                  app.answers[item.question_id+'health_condition1'] = item.conditions[0].health_condition;
                  app.answers[item.question_id+'is_treatment1'] = item.conditions[0].is_treatment;
                  app.answers[item.question_id+'date_of_onset1'] = item.conditions[0].date_of_onset;
                  app.answers[item.question_id+'date_of_recovery1'] = item.conditions[0].date_of_recovery;
                  app.answers[item.question_id+'is_medicate1'] = item.conditions[0].is_medicate;
                  app.answers[item.question_id+'d_last_seen1'] = item.conditions[0].d_last_seen;
                  app.answers[item.question_id+'symptoms1'] = item.conditions[0].symptoms;
                  //prudential specific
                  app.answers[item.question_id+'condition1'] = item.conditions[0].condition;
                  app.answers[item.question_id+'status1'] = item.conditions[0].status;
                  app.answers[item.question_id+'last_seen_by_physician1'] = item.conditions[0].last_seen_by_physician;
                  app.answers[item.question_id+'date_of_recovery1'] = item.conditions[0].date_of_recovery;
                  app.answers[item.question_id+'physician_name1'] = item.conditions[0].physician_name;
                  app.answers[item.question_id+'address1'] = item.conditions[0].address;
                  app.answers[item.question_id+'phone1'] = item.conditions[0].phone;
                }else{
                  app.yesNoAns['q'+item.question_id] = 0;
                  app.yn[item.question_id] = 0;
                }
              });
              if (response.data.data.result.medication_question.yes_no_flag == true) {
                app.yesNoAns['q'+response.data.data.result.medication_question.question_id] = 1;
                app.yn[response.data.data.result.medication_question.question_id] = 1;
                app.medicationLists = response.data.data.result.medication_question.medications
              }else{
                app.yesNoAns['q'+response.data.data.result.medication_question.question_id] = 0;
                app.yn[response.data.data.result.medication_question.question_id] = 0;
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    fetchExistingInfo: function() {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-existing-primary-health-info', {
        params: {
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
            console.log(response.data.data.result);
            if (response.data.status == 'success') {
              app.notes=response.data.data.result.notes;
              response.data.data.result.condition_questions.forEach(function(item) {
                if (item.yes_no_flag == 1) {
                  app.yesNoAns['q'+item.question_id] = 1;
                  app.yn[item.question_id] = 1;
                  app.answers[item.question_id+'health_condition1'] = item.conditions[0].health_condition;
                  app.answers[item.question_id+'is_treatment1'] = item.conditions[0].is_treatment;
                  app.answers[item.question_id+'date_of_onset1'] = item.conditions[0].date_of_onset;
                  app.answers[item.question_id+'date_of_recovery1'] = item.conditions[0].date_of_recovery;
                  app.answers[item.question_id+'is_medicate1'] = item.conditions[0].is_medicate;
                  app.answers[item.question_id+'d_last_seen1'] = item.conditions[0].d_last_seen;
                  app.answers[item.question_id+'symptoms1'] = item.conditions[0].symptoms;
                }else{
                  app.yesNoAns['q'+item.question_id] = 0;
                  app.yn[item.question_id] = 0;
                }
              });
              if (response.data.data.result.medication_question.yes_no_flag == true) {
                app.yesNoAns['q'+response.data.data.result.medication_question.question_id] = 1;
                app.yn[response.data.data.result.medication_question.question_id] = 1;
                app.medicationLists = response.data.data.result.medication_question.medications
              }else{
                app.yesNoAns['q'+response.data.data.result.medication_question.question_id] = 0;
                app.yn[response.data.data.result.medication_question.question_id] = 0;
              }
            }
            app.submitApi('saveExisting');
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    submitApi: function (decleration) {
      const el = this.$refs.health_info;
      (async () => {
        this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'), 'health_info');
      })();
      // Submit API and
      // Navigate to plans page
      let app = this;
      app.finalDataCondAns = [];
      app.finalDataMedAns  = [];
      app.formErrors= [];
      app.formErrorFlag= false;
      // let finalData = [];
      app.questions.forEach(function(item) {
        let row = {};
        let rowAns1 = {};
        // let rowAns2 = {};
        row['conditions'] = [];
        if (app.yesNoAns['q'+item.question_id] == 1) {
          if (item.question_id == 146 || item.question_id == 147) {
            row['question_id'] = item.question_id;
            row['question_text'] = item.question_text;
            row['yes_no_flag'] = 1;
            rowAns1['condition'] = app.answers[item.question_id+'condition1'];
            rowAns1['status'] = app.answers[item.question_id+'status1'];
            rowAns1['last_seen_by_physician'] = app.answers[item.question_id+'last_seen_by_physician1'];
            rowAns1['date_of_recovery'] = app.answers[item.question_id+'date_of_recovery1'];
            rowAns1['physician_name'] = app.answers[item.question_id+'physician_name1'];
            rowAns1['address'] = app.answers[item.question_id+'address1'];
            rowAns1['phone'] = app.answers[item.question_id+'phone1'];
            row['conditions'].push(rowAns1);
            let x = JSON.stringify(row);
            console.log(x);
            // row['conditions'].push(rowAns2);
            app.finalDataCondAns.push(row);
          } else {
            row['question_id'] = item.question_id;
            row['question_text'] = item.question_text;
            row['yes_no_flag'] = 1;
            rowAns1['health_condition'] = app.answers[item.question_id+'health_condition1'];
            rowAns1['date_of_onset'] = app.answers[item.question_id+'date_of_onset1'];
            rowAns1['date_of_recovery'] = app.answers[item.question_id+'date_of_recovery1'];
            rowAns1['is_treatment'] = app.answers[item.question_id+'is_treatment1'];
            rowAns1['is_medicate'] = app.answers[item.question_id+'is_medicate1'];
            rowAns1['d_last_seen'] = app.answers[item.question_id+'d_last_seen1'];
            rowAns1['symptoms'] = app.answers[item.question_id+'symptoms1'];
            // rowAns2['health_condition'] = app.answers[item.question_id+'health_condition2'];
            // rowAns2['date_of_onset'] = app.answers[item.question_id+'date_of_onset2'];
            // rowAns2['date_of_recovery'] = app.answers[item.question_id+'date_of_recovery2'];
            // rowAns2['is_treatment'] = app.answers[item.question_id+'is_treatment2'];
            // rowAns2['is_medicate'] = app.answers[item.question_id+'is_medicate2'];
            // rowAns2['d_last_seen'] = app.answers[item.question_id+'d_last_seen2'];
            // rowAns2['symptoms'] = app.answers[item.question_id+'symptoms2'];
            row['conditions'].push(rowAns1);
            let x = JSON.stringify(row);
            console.log(x);
            // row['conditions'].push(rowAns2);
            app.finalDataCondAns.push(row);
          }
        }else{
          row['question_id'] = item.question_id;
          row['question_text'] = item.question_text;
          row['yes_no_flag'] = 0;
          app.finalDataCondAns.push(row);
        }
      });
      let rowMed = {};
      if (app.medQuestion.question_id > 0 && app.yesNoAns['q'+app.medQuestion.question_id] == 1) {
        rowMed['question_id'] = app.medQuestion.question_id;
        rowMed['question_text'] = app.medQuestion.question_text;
        rowMed['yes_no_flag'] = 1;
        rowMed['medications'] = app.medicationLists;
      }else{
        rowMed['question_id'] = app.medQuestion.question_id;
        rowMed['question_text'] = app.medQuestion.question_text;
        rowMed['yes_no_flag'] = 0;
        rowMed['medications'] = [];
      }
      axios.post(process.env.VUE_APP_API_BASE+'/save-primary-health-info', {
        enrollment_id: app.tempId,
        condition_questions: app.finalDataCondAns,
        medication_question: rowMed,
        notes: app.notes,
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              localStorage.setItem('isSpouseEnrollAccept', false)
              console.log("success");
              app.formErrors= [];
              app.formErrorFlag= false;
              if(decleration == 'saveExisting'){
                window.localStorage.setItem('health_track' , true);
                app.fetchInfo();
              }
              else{
                  app.saveAndContinue(true);
              }

            }
          })
          .catch(function (error) {
            if (error.response.data.status == 'error') {
              error.response.data.data.forEach(function(item) {
                console.log(item.error_message);
                app.formErrorFlag = true;
                app.formErrors[item.target_element+'_message'] = item.error_message;
              });
            }
          });
    },
    setAns: function(id, type) {
      if (type ==  "yes") {
        this.yesNoAns['q'+id] = 1;
      }
      if (type ==  "no") {
        this.yesNoAns['q'+id] = 0;
      }
      console.log(id+'='+this.yesNoAns['q'+id]);
    },
    saveAndContinue() {
      //Update APIs
      //Navigate to next page
      if (localStorage.getItem("navto") === null || localStorage.getItem("navto") === undefined || localStorage.getItem("navto") === '') {
        this.$emit('comEnvNavigation', this.$route.name);
      }else{
        window.localStorage.removeItem('navto');
        this.$router.push('review-confirm');
      }
    },
    saveAndContinueEnrollment: function () {
      let app = this;
      app.finalDataCondAns = [];
      app.finalDataMedAns  = [];
      app.formErrors= [];
      app.formErrorFlag= false;
      // let finalData = [];
      app.questions.forEach(function(item) {
        let row = {};
        let rowAns1 = {};
        // let rowAns2 = {};
        row['conditions'] = [];
        if (app.yesNoAns['q'+item.question_id] == 1) {
          if (item.question_id == 146 || item.question_id == 147) {
            row['question_id'] = item.question_id;
            row['question_text'] = item.question_text;
            row['yes_no_flag'] = 1;
            rowAns1['condition'] = app.answers[item.question_id+'condition1'];
            rowAns1['status'] = app.answers[item.question_id+'status1'];
            rowAns1['last_seen_by_physician'] = app.answers[item.question_id+'last_seen_by_physician1'];
            rowAns1['date_of_recovery'] = app.answers[item.question_id+'date_of_recovery1'];
            rowAns1['physician_name'] = app.answers[item.question_id+'physician_name1'];
            rowAns1['address'] = app.answers[item.question_id+'address1'];
            rowAns1['phone'] = app.answers[item.question_id+'phone1'];
            row['conditions'].push(rowAns1);
            let x = JSON.stringify(row);
            console.log(x);
            // row['conditions'].push(rowAns2);
            app.finalDataCondAns.push(row);
          } else {
            row['question_id'] = item.question_id;
            row['question_text'] = item.question_text;
            row['yes_no_flag'] = 1;
            rowAns1['health_condition'] = app.answers[item.question_id+'health_condition1'];
            rowAns1['date_of_onset'] = app.answers[item.question_id+'date_of_onset1'];
            rowAns1['date_of_recovery'] = app.answers[item.question_id+'date_of_recovery1'];
            rowAns1['is_treatment'] = app.answers[item.question_id+'is_treatment1'];
            rowAns1['is_medicate'] = app.answers[item.question_id+'is_medicate1'];
            rowAns1['d_last_seen'] = app.answers[item.question_id+'d_last_seen1'];
            rowAns1['symptoms'] = app.answers[item.question_id+'symptoms1'];
            // rowAns2['health_condition'] = app.answers[item.question_id+'health_condition2'];
            // rowAns2['date_of_onset'] = app.answers[item.question_id+'date_of_onset2'];
            // rowAns2['date_of_recovery'] = app.answers[item.question_id+'date_of_recovery2'];
            // rowAns2['is_treatment'] = app.answers[item.question_id+'is_treatment2'];
            // rowAns2['is_medicate'] = app.answers[item.question_id+'is_medicate2'];
            // rowAns2['d_last_seen'] = app.answers[item.question_id+'d_last_seen2'];
            // rowAns2['symptoms'] = app.answers[item.question_id+'symptoms2'];
            row['conditions'].push(rowAns1);
            let x = JSON.stringify(row);
            console.log(x);
            // row['conditions'].push(rowAns2);
            app.finalDataCondAns.push(row);
            console.log(app.finalDataCondAns);
          }
        }else{
          row['question_id'] = item.question_id;
          row['question_text'] = item.question_text;
          row['yes_no_flag'] = 0;
          app.finalDataCondAns.push(row);
        }
      });
      let subData2 = '';
      if (app.medQuestion.question_id > 0 && app.yesNoAns['q'+app.medQuestion.question_id] == 1) {
        let rowMed = {};
        let rowMedAns1 = {};
        // let rowMedAns2 = {};
        // let rowMedAns3 = [];
        rowMed['question_id'] = app.medQuestion.question_id;
        rowMed['question_text'] = app.medQuestion.question_text;
        rowMed['yes_no_flag'] = 1;
        rowMed['medications'] = [];
        rowMedAns1['medication'] = app.medAnswers[app.medQuestion.question_id+'medication1'];
        rowMedAns1['dosage'] = app.medAnswers[app.medQuestion.question_id+'dosage1'];
        rowMedAns1['medical_condition'] = app.medAnswers[app.medQuestion.question_id+'medical_condition1'];
        // rowMedAns2['medication'] = app.medAnswers[app.medQuestion.question_id+'medication2'];
        // rowMedAns2['dosage'] = app.medAnswers[app.medQuestion.question_id+'dosage2'];
        // rowMedAns2['medical_condition'] = app.medAnswers[app.medQuestion.question_id+'medical_condition2'];
        // rowMedAns3['medication'] = app.medAnswers[app.medQuestion.question_id+'medication3'];
        // rowMedAns3['dosage'] = app.medAnswers[app.medQuestion.question_id+'dosage3'];
        // rowMedAns3['medical_condition'] = app.medAnswers[app.medQuestion.question_id+'medical_condition3'];
        rowMed['medications'].push(rowMedAns1);
        // rowMed['medications'].push(rowMedAns2);
        let y = JSON.stringify(rowMed);
        // rowMed['medications'].push(rowMedAns2);
        // rowMed['medications'].push(rowMedAns3);
        app.finalDataMedAns.push(y);
        subData2 = JSON.parse(app.finalDataMedAns);
      }else{
        let rowMed = {};
        rowMed['question_id'] = app.medQuestion.question_id;
        rowMed['question_text'] = app.medQuestion.question_text;
        rowMed['yes_no_flag'] = 0;
        rowMed['medications'] = [];
        let y = JSON.stringify(rowMed);
        app.finalDataMedAns.push(y);
        subData2 = JSON.parse(app.finalDataMedAns);
      }
      let params = {
        enrollment_id: app.tempId,
        condition_questions: app.finalDataCondAns,
        medication_question: subData2,
        notes: app.notes,
      };
      for (const key of Object.keys(params)) {
        if (params[key] === null) {
          delete params[key];
        }
      }
      let data = {
        enrollment_id: app.tempId,
        email: app.email,
        tab: 'health_info',
        data: params
      }
      app.formErrorFlag = false;
      app.formErrors    = [];
      axios.post(process.env.VUE_APP_API_BASE+'/save-enrollment', data)
          .then(function (response) {
            if (response.data.status == 'success') {
              app.saveSuccess = true;
              console.log("success");
              // app.saveAndContinue();
            }
          })
          .catch(function (error) {
            if (error.response.data.status == 'error') {
              console.log('error');
              error.response.data.data.forEach(function(item) {
                app.formErrorFlag = true;
                app.formErrors[item.target_element+'_message'] = item.error_message;
              });
              console.log(app.formErrors);
            }
          });
    },
    saveEnrollment : function () {
      let app = this;
      app.$bvModal.show('code-send');
    },
    closePopup : function () {
      let app = this;
      app.savePopup = false;
    },
    navigatePrevious() {
      //Update APIs
      //Navigate to next page
      this.$emit('comEnvNavigationBack', this.$route.name);
    },
    saveScreenShot (image, name) {
      let app = this;
      let data = {
        "enrollment_id" : app.tempId,
        "name" : name,
        "image" : image
      };
      let config = {
        method: 'post',
        url: process.env.VUE_APP_API_BASE+'/save-screenshot',
        headers: { },
        data : data
      };
      axios(config)
          .then(function (response) {
            if (response.data.status == 'success') {
              console.log('screenshot saved');
            }
          })
          .catch(function (error) {
            console.log('screenshot not saved');
            console.log(error);
          });
    },
    scrollTop(){
      window.scrollTo(0,0);
    },
    removeError () {
      this.formErrors = []
    },
    convertTreatmentDateFormat (questionId) {
      let app = this
      let date = app.answers[questionId+'date_of_onset1']
      let dd = date.getDate()
      let mm = date.getMonth() + 1
      let yyyy = date.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      app.answers[questionId+'date_of_onset1'] = mm + '/' + dd + '/' + yyyy;
    },
    convertRecoveryDateFormat (questionId) {
      let app = this
      let date = app.answers[questionId+'date_of_recovery1']
      let dd = date.getDate()
      let mm = date.getMonth() + 1
      let yyyy = date.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      app.answers[questionId+'date_of_recovery1'] = mm + '/' + dd + '/' + yyyy;
    },
    convertPhysicianDateFormat (questionId) {
      let app = this
      let date = app.answers[questionId+'d_last_seen1']
      let dd = date.getDate()
      let mm = date.getMonth() + 1
      let yyyy = date.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      app.answers[questionId+'d_last_seen1'] = mm + '/' + dd + '/' + yyyy;
    },
    convertLastPhysicianDateFormat (questionId) {
      let app = this
      let date = app.answers[questionId+'last_seen_by_physician1']
      let dd = date.getDate()
      let mm = date.getMonth() + 1
      let yyyy = date.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      app.answers[questionId+'last_seen_by_physician1'] = mm + '/' + dd + '/' + yyyy;
    },
    addMedication() {
      let app = this
      app.medicationLists.push({
        medication: '',
        dosage: '',
        medical_condition: ''
      });
    },
    renderEmptyMedication () {
      let app = this
      if (app.medicationLists.length == 0) {
        app.medicationLists.push({
          medication: '',
          dosage: '',
          medical_condition: ''
        });
      }
    },
    removeMedication(medIndex) {
      let app = this
      app.medicationLists.splice(medIndex, 1);
    }
  }
}
</script>

<style scoped>
</style>
