<template>
  <section class="innerpage-wrap">
    <div class="container" ref="saveCart">
      <div v-if="redirectionType == 'save-cart'">
          <send-quote-page :cartPlans="cartPlans"
                           :effective_date.sync="effective_date"
                           @renderEffectiveDate="renderEffectiveDate"
                           @proceedToEnrollment="showConfirmation"
                           @renderPlanDetailInfo="showPlanDetails"
                           :effectiveDateList="effectiveDate"
                           :tempId="tempId"
                           :sendQuoteCartInfo="sendQuoteCartInfo"/>
      </div>
      <div class="row" v-else>
        <div class="col-12">
          <div class="breadcrumb" v-if="checkPlatformRedirection()">
            <ul>
              <li v-if="!checkExtraHealthRedirection()"><a href="#" @click="homePage">Home</a></li>
              <li v-if="isCurrentMemberAvailable"><a style="color:#a2a2a2" @click="routeToCurrentRoute" >Current Member</a></li>
              <li><a href="#" @click="planListing">Plans</a></li>
              <li><a href="#" @click="routeToPlanDetails">Plans Details</a></li>
              <li><a href="#" >Cart</a></li>
            </ul>
          </div>
          <div class="mb-3" v-if="(isFilterDataSubmitted && (!checkExtraHealthRedirection()))">
            <p v-if="hostName !== envAssignHostName || ( hostName == envAssignHostName && open_type == 'group')">Selected Group is: <strong style="color:green;">{{ gname }}
              ({{ gcode }})</strong> <span v-if="usertype==='existing-user'"> | Member: <strong style="color:green;"
                                                                                                class="brand-color">{{
                username
              }}</strong></span>
              <span v-if="portal_name"> |  Selected Portal is: <strong style="color:green;">{{ portal_name }}
                    ({{ portal_code }})</strong> </span>
            </p>
          </div>
        </div>
        <div class="col-12 mt-2">
          <div class="split-title" v-if="hostName !== envAssignHostName">
            <h1 class="brand-color">My Cart</h1>
            <router-link to="" class="back-btn" @click.native="planListing">
              <i class="fas fa-arrow-left"></i> Continue Shopping
            </router-link>
          </div>
          <div class="mycart-title" v-else>
            <h1 class="brand-color">My Cart</h1>
            <router-link to="" class="back-btn ms-4" @click.native="planListing">
              <i class="fas fa-arrow-left"></i> Continue Shopping
            </router-link>
          </div>
        </div>
        <div class="mb-4">
          <div class="msg-alignment">
            <carrier-message-alert :alertMessage="carrierSpecialMessage"
                                   :isL713Plan="isL713Plan"
                                   :l713NetPrice="l713NetPrice" />
          </div>
        </div>
        <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
          <div class="cart-list" v-if="cartPlans.length > 0">
            <div class="table-responsive" style="overflow-x:unset !important">
              <table class="table" style="border-collapse:unset">
                <thead>
                <tr>
                  <th>Product</th>
                  <th>Total Price</th>
                  <th></th>
                </tr>
                </thead>

                <tbody v-for="(plan, index) in cartPlans" v-bind:key="index" >
                <tr v-if="plan.removable == true" >
                  <td style="border-bottom:1px solid !important; border-bottom-color:#ddd">
                    <div class="cart-product">
                      <div class="vendor-img">
                        <img v-bind:src="plan.plan_logo" class="img-fluid" />
                      </div>
                      <div>
                        <h3 style="cursor:pointer"> <a @click="showPlanDetails(plan.plan_id)">{{ plan.plan_name }}</a> </h3>
                        <!-- <p>Effective Date: <select v-model="effective_date" class="custom-input" style="width:70%">
                            <option v-for="eff in effectiveDate" :value="eff" :key="eff">{{eff}}</option>
                          </select></p> -->
                      </div>
                    </div>
                  </td>

                  <td style="border-bottom:1px solid !important; border-bottom-color:#ddd">
                    <div class="price">${{ plan.price }}</div>
                  </td>
                  <td style="border-bottom:1px solid !important; border-bottom-color:#ddd">
                    <div class="action-btn" v-on:click="myFilter(plan.plan_id,plan.plan_pricing_id)">
                      <i
                          class="far fa-trash-alt"
                          v-if="plan.removable == true" id="remPlan"
                      ></i
                      >
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
                 <strong style="font-size: 12.5px;color:red" v-if="checkPrudential == true">
                   Quotes, prices and/or rates displayed include
                   <span v-if="checkForGpbp()">$3</span><span v-else>3%</span>
                   Administrative fees.
                   <span v-if="!checkForGpbp()">Optional</span> Life plans are dependent on Medical Underwriting Approval.
                 </strong>
            </div>
          </div>
          <div class="total_cart" v-else><h2>No items in cart.</h2></div>
        </div>

        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
          <div class="order-info" v-if="cartPlans.length > 0">
<!--            <h2>Order Info</h2>-->
            <label>Effective Date</label>
            <select v-model="effective_date" class="custom-input mb-3">
              <option v-for="eff in effectiveDate" :value="eff" :key="eff">{{eff}}</option>
            </select>
            <div class="table-responsive" >
              <table class="table">
                <tbody>
                <tr v-for="(plan, index) in cartPlans" v-bind:key="index">
                  <td v-if="(plan.removable == false && plan.price !== null && !checkExtraHealthRedirection())">{{ plan.plan_name }}</td>
                  <td v-if="(plan.removable == false && plan.price !== null && !checkExtraHealthRedirection())">${{ plan.price }}</td>
                </tr>
                <tr>
                  <td>First Month Cost</td>
                  <td>${{ cartFirst }}</td>
                </tr>
                <tr>
                  <td>Recurring Cost</td>
                  <td>${{ cartReccur }}</td>
                </tr>
                <tr>
                  <td><strong>Total Cost</strong></td>
                  <td>
                    <strong>${{ cartTotal }}</strong>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <a
                @click="showConfirmation"
                class="checkout-btn"
                style="cursor: pointer; margin-bottom: 1rem"
            >
              Proceed to Enrollment
            </a>
            <a
                @click="viewMorePlans"
                v-if="!checkExtraHealthRedirection()"
                class="checkout-btn"
                style="cursor: pointer; margin-bottom: 1rem"
            >
              Add More Plans
            </a>
            <div>
              <span>
              <a  @click="show_quote_popup"
                  v-if="!checkExtraHealthRedirection()"
                  class="checkout-btn"
                  style="cursor: pointer">
                Save Cart
              </a>
             </span>
            </div>
          </div>
        </div>
        <div class="col-12" v-if="!checkExtraHealthRedirection()">
          <additional-riders />
        </div>
        <div class="col-12" v-if="(checkFarmerCondition() && !checkExtraHealthRedirection())">
          <CartRecommend @planAdded='getCartDetails' />
        </div>
      </div>


      <!-- delete cart plan confirmation pop up -->
      <b-modal v-bind:hide-footer="true" id="remove-plan" centered size="md" >
        <template #modal-header>
          <div class="popUp-header" style="border-bottom: 0px !important">
            <h4>Do you really want to remove?</h4>
            <b-button variant="close-btn" @click="$bvModal.hide('remove-plan')">
              <i class="fas fa-times"></i>
            </b-button>
          </div>
        </template>
        <b-container fluid>
          <div class="popUp-details text-center">
            <div class="action-btn text-center mt-4">
              <b-button style="width:40%;"  class="dark-btn mx-1" @click="$bvModal.hide('remove-plan')">No</b-button>
              <b-button style="width:40%;"  class="button-brand mx-1" @click="removePlan" data-dismiss="modal">Yes</b-button>
            </div>
          </div>
        </b-container>

      </b-modal>


      <!-- personalize quote form popup -->
      <b-modal v-bind:hide-footer="true"  id="send-quote" size="lg" centered>
        <template #modal-header>
          <div class="popUp-header" style="border-bottom: 0px !important">
            <h4>Save Cart</h4>
            <b-button variant="close-btn" @click="$bvModal.hide('send-quote')">
              <i class="fas fa-times"></i>
            </b-button>
          </div>
        </template>
        <b-container fluid>
          <div class="enrollmentForm">
            <form class="" action="" method="post">
              <div class="form-group row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                  <label for="">Name <span class="required">*</span></label>
                  <input type="text"
                         class="custom-input"
                         @input="$v.name.$touch(); removeError();"
                         :class="{'form-control is-invalid':$v.name.$error }"
                         v-model="name"
                         value="" />
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.name.required && $v.name.$error">
                    Field is required
                  </div>
                  <div class="form-group__message  ml-3 mt-2" v-if="$v.name.required && $v.name.$error">
                    Please enter valid name
                  </div>
                  <div style="color:red" class="error_message" v-if="formErrorFlag == true">
                    {{ formErrors["name_message"] }}
                  </div>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                  <label for="">Email <span class="required">*</span></label>
                  <input type="text"
                         class="custom-input"
                         @input="$v.email.$touch(); removeError();"
                         :class="{'form-control is-invalid':$v.email.$error }"
                         v-model="email"
                         value="" />
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.email.required && $v.email.$error">
                    Field is required
                  </div>
                  <div class="form-group__message text-left ml-3 mt-2" v-if="$v.email.required && $v.email.$invalid">
                    Please enter valid email address
                  </div>
                  <div style="color:red"
                       class="error_message" v-if="formErrorFlag == true">
                    {{ formErrors["email_message"] }}
                  </div>
                </div>
                <div class="col-12  mb-4">
                  <label for="">Message</label>
                  <textarea
                      id="textarea"
                      class="custom-input"
                      rows="5"
                      v-model="message"
                  ></textarea>
                  <div style="color:red" class="error_message" v-if="formErrorFlag == true">
                    {{ formErrors["message_message"] }}
                  </div>
                </div>
              </div>
            </form>
            <div class="action-btn text-center mt-6">
              <strong style="font-size: 9px;color:red" v-if="checkPrudential == true">Quotes, prices and/or rates displayed include  <span v-if="checkForGpbp()">$3</span><span v-else>3%</span> Administrative fees.</strong>
              <div class="row">
                <div class="col-md-6 col-sm-6 mb-2">
                  <b-button class="dark-btn w-100" @click="loadQuotePreviewContent">Preview</b-button>
                </div>
                <div class="col-md-6 col-sm-6">
                  <b-button class="button-brand w-100" @click="saveAndContinueEnrollment">Send</b-button>
                </div>
              </div>


            </div>
          </div>
        </b-container>
      </b-modal>


      <!-- plan detail popup -->
      <div v-show="planDetailPop" class="popups">
        <div class="popup_container">
          <a @click="planDetailPop = false" class="close" href="#">x</a>
          <h1>{{ planDetails.planName }}</h1>
          <div class="content grey_box">
            <div class="scroller">
              <div class="plan_desc" style="padding-top: 2em">
                <div class="plandesc_content">
                  <div class="scr_detail">
                    <div v-html="planDetails.planDesc"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Confirmation page pop up start -->
      <div>
        <b-modal id="cart_confirmation_page" v-bind:hide-footer="true" centered  size="md">
          <template #modal-header>
            <div class="popUp-header" style="border-bottom: 0px !important">
              <h4>Cart page confirmation to proceed further</h4>
              <b-button variant="close-btn" @click="$bvModal.hide('cart_confirmation_page')">
                <i class="fas fa-times"></i>
              </b-button>
            </div>
          </template>
          <b-container fluid>

            <confirmation @hidePopUp="hideconfirmation" @Checkoutcart="cartCheckout" :effective="effective_date" />

          </b-container>
        </b-modal>
      </div>
      <!-- Confirmation page pop up end -->

      <div>
        <b-modal id="plan-Detail-Pop" v-bind:hide-footer="true" centered  size="xl">
          <template #modal-header>
            <div class="popUp-header" style="border-bottom: 0px !important">
              <h4>Plan details</h4>
              <b-button variant="close-btn" @click="$bvModal.hide('plan-Detail-Pop')">
                <i class="fas fa-times"></i>
              </b-button>
            </div>
          </template>
          <b-container fluid>
            <div class="popUp-details">
              <h2>{{ planDetails.planName }}</h2>
              <div v-html="planDetails.planDesc"></div>
              <div class="action-btn text-center">
                <b-button class="button-brand mx-1" @click="$bvModal.hide('plan-Detail-Pop')">OK</b-button>
              </div>
            </div>
          </b-container>

        </b-modal>
      </div>
      <!-- quote successfull popup -->
      <div>
        <b-modal v-bind:hide-footer="true" centered size="md" id="quote-confirmation">
          <template #modal-header>
            <div class="popUp-header" style="border-bottom: 0px !important">
              <h4>Confirmation</h4>
              <b-button variant="close-btn" @click="$bvModal.hide('quote-confirmation')">
                <i class="fas fa-times"></i>
              </b-button>
            </div>
          </template>
          <b-container fluid>
            <div style="text-align:center;" class="popUp-details">
              <label >Quote Successfully Sent to {{ quoteDisplayEmail }}</label>
              <div class="action-btn text-center mt-4">
                <b-button class="button-brand mx-1" style="width:40%" @click="$bvModal.hide('quote-confirmation')">OK</b-button>
              </div>
            </div>
          </b-container>
        </b-modal>
      </div>


      <!-- Quote html preview -->
      <div>
        <b-modal id="preview" v-bind:hide-footer="true" centered size="lg">
          <template #modal-header>
            <div class="popUp-header" style="border-bottom: 0px !important">
              <h4>Preview</h4>
              <b-button variant="close-btn" @click="$bvModal.hide('preview')">
                <i class="fas fa-times"></i>
              </b-button>
            </div>
          </template>
          <b-container fluid>
            <div v-html="htmlContent"
                 style="overflow-y: scroll !important; max-height: 500px"></div>
            <div class="action-btn text-center mt-4">
              <b-button class="button-brand mx-1" @click="$bvModal.hide('preview')">OK</b-button>
            </div>
          </b-container>
        </b-modal>
      </div>
    </div>
  </section>
</template>

<style scoped>
form {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}
</style>

<script>
import axios from "axios";
import Api from '../includes/Api'
import html2canvas from "html2canvas";
import Confirmation from '../components/Confirmation.vue';
import {required, email} from "vuelidate/lib/validators";
import {onlyAlphabet} from "../utils/validators";
import CartRecommend from "../components/CartRecommend.vue";
import CarrierMessageAlert from "../components/CarrierMessageAlert";
import SendQuotePage from "./SendQuotePage";
import {checkExtraHealthGroup, checkPlatform} from "../utils/checkPlatform";
export default {
  components: { Confirmation, CartRecommend, CarrierMessageAlert, SendQuotePage },
  name: "Cart",
  data: function () {
    return {
      tempId: "",
      cartPlans: [],
      cartTotal: "",
      cartFirst: "",
      cartReccur: "",
      category: "",
      dob: "",
      tier: "",
      state: "",
      zip: "",
      effectiveDate: [],
      gender: "",
      annualIncome: "",
      smoker: "",
      productFlag: false,
      gname: "",
      gcode: "",
      username: "",
      usertype: "",
      formErrors: [],
      formErrorFlag: false,
      savePopup: false,
      email: "",
      saveSuccess: false,
      name: "",
      showQuotation: true,
      eprocess: "",
      isActive: false,
      deletePlanId: "",
      deletePricingId: "",
      confirmationPopup: false,
      message: "",
      previewDialog: false,
      htmlContent: "",
      effective_date: "",
      effective_error: false,
      effective_error_msg: "",
      planDetailPop: false,
      planDetails: {
        planName: "",
        planDesc: "",
      },
      checkPrudential: false,
      isFilterDataSubmitted: false,
      portal_name: '',
      portal_code: null,
      isCurrentMemberAvailable: false,
      personalCodeRedirection: false,
      hostName: location.host,
      envAssignHostName: process.env.VUE_APP_SITE_HOST_NAME,
      quoteDisplayEmail: '',
      carrierSpecialMessage: '',
      isL713Plan: false,
      l713NetPrice: null,
      sendQuoteCartInfo: {},
      redirectionType: '',
      platformType: ''
    };
  },
  validations: {
    name: {
      required,
      onlyAlphabet
    },
    email: {
      required,
      email
    }
  },
  computed: {
    landingInfo () {
      return this.$store.getters.getLandingInfo
    },
    employeeGroupCode () {
      return this.$store.getters.getEmployeeGroupCode
    }
  },
  mounted() {
    let app = this;
    app.tempId = window.localStorage.getItem("tempId");
    app.personalCodeRedirection = window.localStorage.getItem('personalCodeRedirection');
    app.eprocess = window.localStorage.getItem("eprocess");
    app.open_type = window.localStorage.getItem("open_type");
    app.referral = window.localStorage.getItem("referral");
    app.portal_name = window.localStorage.getItem('portal_name');
    app.portal_code = window.localStorage.getItem('portal_code');
    app.usertype = window.localStorage.getItem('usertype');
    app.username = window.localStorage.getItem('username');
    app.gname = window.localStorage.getItem('gname');
    app.gcode = window.localStorage.getItem('gcode');
    app.isFilterDataSubmitted = window.localStorage.getItem('isFilterDataSubmitted');
    app.planId = window.localStorage.getItem('planId');
    app.planName = window.localStorage.getItem('planName');
    app.isCurrentMemberAvailable = window.localStorage.getItem('isCurrentMemberAvailable');
    app.redirectionType = window.localStorage.getItem('redirectionType');
    app.platformType = localStorage.getItem('platformType');
    app.getInfo();
    app.getStoredEffectiveDate();
    app.getCartDetails();
    this.$root.$emit("cartData", app.tempId);
    app.showQuotation = window.localStorage.getItem("show-quotation");
    app.checkPrudentialCart();
  },
  methods: {
    myFilter: function (plan_id, plan_pricing_id) {
      this.isActive = true;
      this.deletePlanId = plan_id;
      this.deletePricingId = plan_pricing_id;
      this.$bvModal.show('remove-plan');
    },
    checkForGpbp () {
      let app = this
      if (app.landingInfo && app.landingInfo.data && app.landingInfo.data.gcode === 'GOV8358') {
        return true
      } else {
        return  false
      }
    },
    popupClose: function () {
      this.isActive = false;
    },
    show_quote_popup: function(){
      let app =this;
      app.$bvModal.show('send-quote');
    },
    viewMorePlans () {
      let app = this
      app.$router.push('/plans')
    },
    renderEffectiveDate (effectiveDate) {
      let app = this
      app.effective_date = effectiveDate
    },
    checkPrudentialCart() {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/check-prudential-cart', {
        params: {
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.checkPrudential = response.data.data.status;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    plansPage: function () {
      this.$router.push({ name: "plans" });
    },
    getNavTabs: function() {
      let nav_array = [];
      Api.get('/get-dynamic-tabs?enrollment_id='+ this.tempId).then((response) => {
        console.log(response.data.data.result);
        let index = 1;
        let order = 0;
        for (order = 0; order <= 10; order++) {
          response.data.data.result.forEach((nav) => {
            if(nav.order == order){
              if(nav.tab_name == 'primary_info' && nav.required){
                nav_array.push({"index": index, "title": 'Personal Information', "name": 'personal-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'contact_info' && nav.required){
                nav_array.push( {"index": index, "title": 'Contact Information', "name": 'contact-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'employer_info' && nav.required){
                nav_array.push({"index": index, "title": 'Employer Information', "name": 'employer-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'beneficiary_info' && nav.required){
                nav_array.push({"index": index, "title": 'Beneficiary Information', "name": 'beneficiary-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'primary_health_info' && nav.required){
                nav_array.push({"index": index, "title": 'Health Questions', "name": 'health-questions', "visible": true});
                index++;
              }
              if(nav.tab_name == 'dependent_info' && nav.required){
                nav_array.push({"index": index, "title": 'Dependent Information', "name": 'dependent-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'review_info' && nav.required){
                nav_array.push({"index": index, "title": 'Review & Confirmation', "name": 'review-confirm', "visible": true});
                index++;
              }
              if(nav.tab_name == 'agreement_info' && nav.required){
                nav_array.push({"index": index, "title": 'Agreement & Signature', "name": 'agreement-signature', "visible": true});
                index++;
              }
              if(nav.tab_name == 'billing_info' && nav.required){
                nav_array.push({"index": index, "title": 'Billing Information', "name": 'billing-information', "visible": true});
                index++;
              }
            }
          });
        }
        window.localStorage.setItem("nav_array", JSON.stringify(nav_array));
      }).catch(() => {
      }).then(() => {
      });
    },
    showConfirmation: function(){
      let app = this;
      app.getNavTabs();
      app.$bvModal.show('cart_confirmation_page');
    },
    getCartDetails: function () {
      let app = this;
      axios
          .get(process.env.VUE_APP_API_BASE + "/get-plan-cart", {
            params: {
              enrollment_id: app.tempId,
            },
          })
          .then(function (response) {
            console.log(response.data);
            if (response.data.status == "success") {
              response.data.data.result.forEach(function (item) {
                app.cartPlans.push(item);
              });
              app.sendQuoteCartInfo = {}
              app.cartFirst = response.data.data.initial_month_cost;
              app.cartReccur = response.data.data.recurring_month_cost;
              app.cartTotal = response.data.data.initial_month_cost;
              app.carrierSpecialMessage =  response.data.data.special_message;
              app.isL713Plan = response.data.data.has_l713;
              app.l713NetPrice = response.data.data.l713_net_price;
              app.sendQuoteCartInfo ['cartFirst'] = app.cartFirst
              app.sendQuoteCartInfo ['cartReccur'] = app.cartReccur
              app.sendQuoteCartInfo ['cartTotal'] = app.cartTotal
              app.sendQuoteCartInfo ['quote_name'] = response.data.data.quote_name
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getCartTotal: function () {
      let app = this;
      let pid = app.$route.params.id;
      axios
          .get(process.env.VUE_APP_API_BASE + "/get-plan-pricing-detail", {
            params: {
              pid: pid,
              enrollment_id: app.tempId,
            },
          })
          .then(function (response) {
            console.log(response.data);
            if (response.data.status == "success") {
              response.data.plans.forEach(function (item) {
                app.plans.push(item);
              });
            } else {
              if (
                  response.data.status == "error" &&
                  response.data.statusCode == "changePid"
              ) {
                console.log("error");
                app.reloadPage(response.data.pid);
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getEffDate: function () {
      let app = this;
      console.log("final" + app.effective_date);
      axios
          .get(process.env.VUE_APP_API_BASE + "/get-cart-effective-dates", {
            params: {
              enrollment_id: app.tempId,
            },
          })
          .then(function (response) {
            if (response.data.status == "success") {
              app.effectiveDate = [];
              response.data.data.result.forEach(function (item) {
                app.effectiveDate.push(item);
              });
            }
            if (app.effective_date == null || app.effective_date == "") {
              app.effective_date = app.effectiveDate[0];
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    cartCheckout: function () {
      const el = this.$refs.saveCart;
      (async () => {
        this.saveScreenShot(
            (await html2canvas(el)).toDataURL("image/png"),
            "cart"
        );
      })();
      let app = this;
      axios
          .post(process.env.VUE_APP_API_BASE + "/cart-checkout", {
            enrollment_id: app.tempId,
            effective_date: app.effective_date,
          })
          .then(function (response) {
            if (response.data.status == "success") {
              app.$bvModal.hide('cart_confirmation_page');
              window.localStorage.setItem('effectiveDate', app.effective_date)
            }
          })
          .catch(function (error) {
            if (error.response.data.status == "error") {
              error.response.data.data.forEach(function (item) {
                if (item.target_element == "effective_date") {
                  app.effective_error = true;
                  app.effective_error_msg = item.error_message;
                }
              });
            }
            console.log(error);
          });
    },
    hideconfirmation(){
      let app = this;
      app.$bvModal.hide('cart_confirmation_page');
    },
    removePlan: function () {
      let app = this;
      axios
          .get(process.env.VUE_APP_API_BASE + "/remove-plan-from-cart", {
            params: {
              enrollment_id: app.tempId,
              plan_id: app.deletePlanId,
              plan_pricing_id: app.deletePricingId,
            },
          })
          .then(function (response) {
            console.log(response);
            if (response.data.status == "success") {
              app.cartPlans = [];
              // response.data.data.result.forEach(function(item) {
              //   app.cartPlans.push(item);
              // });
              app.getStoredEffectiveDate();
              app.getCartDetails();
              app.$root.$emit("cartData", app.tempId);
              app.$bvModal.hide('remove-plan');
              app.checkPrudentialCart();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getStoredEffectiveDate: function () {
      let app = this;
      axios
          .get(process.env.VUE_APP_API_BASE + "/get-enrollment-temp-info", {
            params: {
              enrollment_id: app.tempId,
              column: "effective_date",
            },
          })
          .then(function (response) {
            if (response.data.status == "success") {
              app.effective_date = "";
              if (response.data.data.result != null) {
                app.effective_date = response.data.data.result;
              }
              app.getEffDate();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    saveEnrollment: function () {
      let app = this;
      app.savePopup = true;
    },
    closePopup: function () {
      let app = this;
      app.formErrors = [];
      app.formErrorFlag = false;
      app.email = "";
      app.name = "";
      app.saveSuccess = false;
      app.savePopup = false;
    },
    showPlanDetails: function (pid) {
      let app = this;
      axios
          .get(process.env.VUE_APP_API_BASE + "/get-plan-detail/" + pid)
          .then(function (response) {
            if (response.data.status == "success") {
              app.planDetails.planDesc = app.removeSpecialSymbol(response.data.data.plan_desc.body_text);
              app.planDetails.planName = response.data.data.plan_name;
              app.$bvModal.show('plan-Detail-Pop');
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    removeSpecialSymbol (stringChar) {
      let stringArray= stringChar;
      let planDesc = "";
      for (let i=0; i<stringArray.length; i++) {
        if (stringArray.charCodeAt(i) <= 127) {
          planDesc += stringArray.charAt(i);
        } else {
          planDesc += stringArray.charAt(i).replace(/[^A-Z0-9]/ig, ' ')
        }
      }
      return planDesc
    },
    saveAndContinueEnrollment: function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log('***** Inside Save Data Invalid*****')
        return false
      } else {
        let app = this;
        let data = {
          enrollment_id: app.tempId,
          email: app.email,
          name: app.name,
          tab: "cart_info",
          message: app.message,
          data: {},
        };
        console.log('for data check' + JSON.stringify(data))
        app.formErrorFlag = false;
        app.formErrors = [];
        axios
            .post(process.env.VUE_APP_API_BASE + "/save-enrollment", data)
            .then(function (response) {
              if (response.data.status == "success") {
                app.$bvModal.hide('send-quote');
                app.saveSuccess = true;
                app.savePopup = false;
                app.$bvModal.show("quote-confirmation");
                app.quoteDisplayEmail = response.data.data.result.email
                console.log("success")
                app.name = ''
                app.email = ''
                app.message = ''
                app.$v.$reset();
              }
            })
            .catch(function (error) {
              if (error.response.data.status == "error") {
                console.log("error");
                error.response.data.data.forEach(function (item) {
                  app.formErrorFlag = true;
                  app.formErrors[item.target_element + "_message"] =
                      item.error_message;
                });
                console.log(app.formErrors);
              }
            });
      }
    },
    loadQuotePreviewContent: function () {
      let app = this;
      app.formErrorFlag = false;
      app.formErrors = [];
      axios
          .get(process.env.VUE_APP_API_BASE + "/get-quote-preview-content", {
            params: {
              enrollment_id: app.tempId,
              email: app.email,
              name: app.name,
              tab: "cart_info",
              message: app.message,
              type: "preview",
            },
          })
          .then(function (response) {
            if (response.data.status == "success") {
              app.htmlContent = response.data.data.content;
              app.$bvModal.show("preview");
            }
          })
          .catch(function (error) {
            if (error.response.data.status == "error") {
              console.log("error");
              error.response.data.data.forEach(function (item) {
                app.formErrorFlag = true;
                app.formErrors[item.target_element + "_message"] =
                    item.error_message;
              });
              console.log(app.formErrors);
            }
            console.log(error);
          });
    },
    saveScreenShot(image, name) {
      let app = this;
      let data = {
        enrollment_id: app.tempId,
        name: name,
        image: image,
      };
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_BASE + "/save-screenshot",
        headers: {},
        data: data,
      };
      axios(config)
          .then(function (response) {
            if (response.data.status == "success") {
              console.log("screenshot saved");
            }
          })
          .catch(function (error) {
            console.log("screenshot not saved");
            console.log(error);
          });
    },
    nextPage: function () {
      this.$router.push("confirmation");
    },
    reloadPage: function () {
      // this.$router.push('/cart');
    },
    homePage: function () {
      if (this.open_type !== null && typeof this.open_type !== "undefined") {
        if (this.referral !== null && typeof this.referral !== "undefined") {
          this.$router.push("/referrals?rid=" + this.referral);
        } else if ((this.eprocess == 'applicant' && this.open_type == 'group') || (this.eprocess == 'applicant' && this.open_type == 'agent')) {
          this.$router.push(`/${this.landingName}`)
        } else {
          this.$router.push("/");
        }
      } else {
        if (this.eprocess === "rep") {
          this.$router.push("/agent-home");
        } else if (this.eprocess === "applicant") {
          this.$router.push("/member-home");
        } else if (this.eprocess === "group") {
          if (location.host == process.env.VUE_APP_SITE_HOST_NAME) {
            this.$router.push('/');
          } else {
            this.$router.push('/group-home');
          }
        }
      }
    },
    planListing: function () {
      console.log("retun to plan listing");
      localStorage.removeItem('menuSelectedItem')
      localStorage.removeItem('selectedCategorykey');
      localStorage.removeItem('selectedCategoryValue');
      this.$router.push("/plans");
    },
    previewMessage: function () {
      this.previewDialog = true;
    },
    removeError () {
      this.formErrors = []
    },
    routeToPlanDetails () {
      this.$router.push(`/plan/${this.planId}/${this.planName}`)
    },
    routeToCurrentRoute () {
      if (this.open_type !== null && typeof(this.open_type) !== 'undefined') {
        if (this.referral !== null && typeof(this.referral) !== 'undefined') {
          this.$router.push('/referrals?rid='+this.referral);
        } else {
          this.$router.push('/');
        }
      } else {
        if (this.eprocess === 'rep') {
          this.$router.push('/agent-select-member');
        }else if (this.eprocess === 'applicant') {
          this.$router.push('/member-home');
        } else if (this.eprocess === 'group') {
          this.$router.push('/group-select-member');
        }
      }
    },
    getInfo: function() {
      let app = this;
      if(app.tempId !=null && app.personalCodeRedirection === 'Yes'){
        axios.get(process.env.VUE_APP_API_BASE+'/get-group-agent-member-info?enrollment_id='+app.tempId)
            .then(function (response) {
              if (response.data.status == 'success') {
                app.usertype = response.data.data.enrollment_type;
                app.isFilterDataSubmitted = true;
                localStorage.setItem('isFilterDataSubmitted', true)
                if (response.data.data.enrollment_type == 'existing-user') {
                  app.username = response.data.data.result.user_info.first_name+' '+response.data.data.result.user_info.last_name;
                }
                app.gname = response.data.data.result.group_info.name;
                app.gcode = response.data.data.result.group_info.code;
                app.portal_name = response.data.data.result.platforms[0].platform_name;
                app.portal_code = response.data.data.result.platforms[0].platform_code;
                if (  app.usertype ) localStorage.setItem('usertype', app.usertype)
                if (  app.username ) localStorage.setItem('username', app.username)
                if (  app.gname ) localStorage.setItem('gname', app.gname)
                if (  app.gcode ) localStorage.setItem('gcode', app.gcode)
                if (  app.portal_name ) localStorage.setItem('portal_name', app.portal_name)
                if (  app.portal_code ) localStorage.setItem('portal_code', app.portal_code)
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      }
    },
    checkFarmerCondition () {
      let app = this
      if ((app.landingInfo?.data?.gcode == 'FARMERS') || (app.employeeGroupCode == 'FARMERS')) {
        return false
      } else {
        return true
      }
    },
    checkPlatformRedirection () {
      let app = this
      return (checkPlatform(app.platformType))
    },
    checkExtraHealthRedirection () {
      let app = this
      return (checkExtraHealthGroup(app.landingInfo))
    }
  },
};
</script>

<style scoped lang="scss">
.global_popup .modal-content{
  border: 0px !important;
  border-radius: 10px;
}
form {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}
.recommended-wrap{
  left:10px !important;
}
table>:not(caption)>*>*  {
  background-color: transparent !important;
}
</style>
