import store from '../store'

export function checkFarmerSiteRedirection (landingInfo, groupCode) {
 if (landingInfo && landingInfo.data && landingInfo.data.gcode == 'FARMERS') {
  store.dispatch("isPatriotOver65", false);
  return true
 } else if (groupCode == 'FARMERS') {
  store.dispatch("isPatriotOver65", false);
  return true
 } else {
  return false
 }
}