<template>
  <div>
    <h2>{{label}}</h2>
    <div class="row mb-5">
      <div
          class="col-xxl-4 col-md-4 col-sm-6 mb-3"
          v-for="(item, index) in filteredPlans"
          :key="index">
        <div class="new-plan-card call-text-container">
          <call-out-text :callOutText="item.callout_text" />
          <div class="">
            <div class="img-wrap">
              <img :src="item.plan_logo" />
            </div>
            <h5>{{ item.plan_name }}</h5>
            <ul>
              <p v-html="item.plan_short_text"></p>
            </ul>
          </div>
          <div>
            <div>
              <price-detail-info :priceInfo="item?.tier_price"
                                 :isPlanOnCart="item?.is_in_cart"
                                 @getPlanPricing="addPlan"
                                 :items="item"/>
            </div>
            <div class="plan-button">
              <button class="view-btn" @click="planDetails(item.plan_id)">
                View More Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PriceDetailInfo from "./PriceDetailInfoNewHome.vue";
import CallOutText from "../CallOutText";

export default {
  name: 'BundlePlan',
  components: {PriceDetailInfo, CallOutText},
  props: {
    label: {
      type: String,
      require: false
    },
    filteredPlans: {
      type: Array,
      require: true
    }
  },
  methods: {
    addPlan (items) {
      let app = this
      app.$emit('addPlanToCart', items)
    },
    planDetails (items) {
      let app = this
      app.$emit('routeToViewPlan', items)
    }
  }
}
</script>
<style scoped>
h2 {
  font-size: 22px;
  font-weight: 600;
  color: #000;
  margin-bottom: 1.5rem;
}
</style>
