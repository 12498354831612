<template>
  <div class="extra-health-agreement" v-if="checkExtraHealthRedirection()">
    <h1>Billing Agreement</h1>

    <h2><strong>1. Overview:</strong></h2>
    <p>
      This Billing Agreement (“Agreement”) is entered into between Extra Health (“Company”) and you (“Customer”).
      By entering into this Agreement, Customer authorizes the Company to charge the Customer’s credit card on
      a recurring basis as outlined below.
    </p>

    <h2><strong>2. Payment Terms:</strong></h2>
    <ol>
      <li>
        <strong>Initial Payment:</strong> The first month’s payment will be billed on the date of enrollment to cover services for the initial month.
      </li>
      <li>
        <strong>Recurring Billing:</strong><span> Payments are scheduled to be charged on the 10th of each month to cover services for the following month.</span>
      </li>
      <li>
        <strong>Recurring Attempts:</strong> If the initial charge on the 10th fails, the Company will make additional attempts to process the payment on:</li>
          <ul>
            <li>The 15th of the month</li>
            <li>The 20th of the month</li>
            <li>The 25th of the month</li>
          </ul>
      <li>
        <strong>Account Termination:</strong><span> If the charge remains unpaid after the final attempt on the 25th, the Customer’s account will be terminated immediately.</span>
      </li>
    </ol>

    <h2><strong>3. Payment Method:</strong></h2>
    <p>
      Customer authorizes the Company to charge their credit card provided. It is the Customer’s responsibility to ensure
      that the credit card information provided is valid and up-to-date.
    </p>

    <h2><strong>4. Notification of Failed Payment:</strong></h2>
    <ol>
      <li>The Company will notify the Customer via email within 24 hours of a failed payment attempt.</li>
      <li>It is the Customer’s responsibility to ensure the Company has updated contact information to receive notifications.</li>
    </ol>

    <h2><strong>5. Account Termination:</strong></h2>
    <ol>
      <li>
        Accounts terminated due to non-payment after the final charge attempt on the 25th are subject to the following:
        <ul>
          <li>Immediate suspension of services.</li>
          <li>Possible reactivation fees if the account is reinstated.</li>
        </ul>
      </li>
    </ol>

    <h2><strong>6. Cancellation Policy:</strong></h2>
    <p>
      The Customer may cancel this billing agreement at any time by providing a written notice to the Company at least 10 days prior to
      the next billing cycle. Cancellation requests must be sent to <a href="mailto:support@enrollprime.com">support@enrollprime.com</a>.
      Any payments already processed are non-refundable.
    </p>

    <h2><strong>7. Agreement Modifications:</strong></h2>
    <p>
      The Company reserves the right to modify the terms of this Agreement with 30 days’ notice. Continued use of the services after
      such notice constitutes acceptance of the new terms.
    </p>

    <h2><strong>8. Customer Acknowledgment:</strong></h2>
    <p>
      By signing below, the Customer acknowledges and agrees to the terms outlined in this Billing Agreement.
    </p>
  </div>
</template>

<script>
import {checkExtraHealthGroup} from "@/utils/checkPlatform";

export default {
  name: 'ExtraHealthAgreement',
  computed: {
    landingInfo () {
      return this.$store.getters.getLandingInfo
    }
  },
  methods: {
    checkExtraHealthRedirection () {
      let app = this
      return (checkExtraHealthGroup(app.landingInfo))
    }
  }
}
</script>

<style scoped lang="scss">
.extra-health-agreement {
  line-height: 1.6;
  padding: 20px;
  border: 1px solid #ddd;
  text-align: justify;
  margin-bottom: 25px;

  h1 {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 16px;
    color: #000000;
    margin-top: 20px;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  p {
    font-size: 1rem;
    margin: 10px 0;
    padding-left: 16px;
  }

  ol {
    margin: 10px 0 10px 34px;
    padding: 0;
  }

  ol li::marker {
    font-weight: bold;
    color: #000000;
  }

    ul {
    margin: 5px 0 10px 20px;
    padding: 0;
  }

  li {
    margin-bottom: 5px;
  }

  strong {
    color: #333;
    font-weight: bold;
  }

}
</style>
