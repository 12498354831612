var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.checkExtraHealthRedirection())?_c('div',{staticClass:"extra-health-agreement"},[_c('h1',[_vm._v("Billing Agreement")]),_vm._m(0),_c('p',[_vm._v(" This Billing Agreement (“Agreement”) is entered into between Extra Health (“Company”) and you (“Customer”). By entering into this Agreement, Customer authorizes the Company to charge the Customer’s credit card on a recurring basis as outlined below. ")]),_vm._m(1),_vm._m(2),_vm._m(3),_c('p',[_vm._v(" Customer authorizes the Company to charge their credit card provided. It is the Customer’s responsibility to ensure that the credit card information provided is valid and up-to-date. ")]),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_c('p',[_vm._v(" The Company reserves the right to modify the terms of this Agreement with 30 days’ notice. Continued use of the services after such notice constitutes acceptance of the new terms. ")]),_vm._m(11),_c('p',[_vm._v(" By signing below, the Customer acknowledges and agrees to the terms outlined in this Billing Agreement. ")])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('strong',[_vm._v("1. Overview:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('strong',[_vm._v("2. Payment Terms:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_c('strong',[_vm._v("Initial Payment:")]),_vm._v(" The first month’s payment will be billed on the date of enrollment to cover services for the initial month. ")]),_c('li',[_c('strong',[_vm._v("Recurring Billing:")]),_c('span',[_vm._v(" Payments are scheduled to be charged on the 10th of each month to cover services for the following month.")])]),_c('li',[_c('strong',[_vm._v("Recurring Attempts:")]),_vm._v(" If the initial charge on the 10th fails, the Company will make additional attempts to process the payment on:")]),_c('ul',[_c('li',[_vm._v("The 15th of the month")]),_c('li',[_vm._v("The 20th of the month")]),_c('li',[_vm._v("The 25th of the month")])]),_c('li',[_c('strong',[_vm._v("Account Termination:")]),_c('span',[_vm._v(" If the charge remains unpaid after the final attempt on the 25th, the Customer’s account will be terminated immediately.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('strong',[_vm._v("3. Payment Method:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('strong',[_vm._v("4. Notification of Failed Payment:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("The Company will notify the Customer via email within 24 hours of a failed payment attempt.")]),_c('li',[_vm._v("It is the Customer’s responsibility to ensure the Company has updated contact information to receive notifications.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('strong',[_vm._v("5. Account Termination:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v(" Accounts terminated due to non-payment after the final charge attempt on the 25th are subject to the following: "),_c('ul',[_c('li',[_vm._v("Immediate suspension of services.")]),_c('li',[_vm._v("Possible reactivation fees if the account is reinstated.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('strong',[_vm._v("6. Cancellation Policy:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" The Customer may cancel this billing agreement at any time by providing a written notice to the Company at least 10 days prior to the next billing cycle. Cancellation requests must be sent to "),_c('a',{attrs:{"href":"mailto:support@enrollprime.com"}},[_vm._v("support@enrollprime.com")]),_vm._v(". Any payments already processed are non-refundable. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('strong',[_vm._v("7. Agreement Modifications:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('strong',[_vm._v("8. Customer Acknowledgment:")])])
}]

export { render, staticRenderFns }