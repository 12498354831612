<template>
  <div class="Dependency-info">
    <div class="Dependency-info-content">
      <div class="description-wrap">
        <ul>
          <li v-if="(tier == 'IS' || tier == 'IF') && spouse_dob">
            <div class="render-block">
              Spouse DOB: <span class="brand-color">{{ spouse_dob }}</span>
            </div>
          </li>
          <li v-if="(tier == 'IC' || tier == 'IF') && childs">
            <div class="render-block">
              No. of Children: <span class="brand-color">{{ childs }}</span>
            </div>
          </li>
          <li v-if="!isSubmitHidden">
            <b-button v-b-modal.Dependency-info modal-centered class="univ-btn"
              >{{ spouse_dob || childs ? "Edit" : "Add" }} Dependent
              Information</b-button
            >
          </li>
        </ul>
      </div>
    </div>
    <DependentModal
      :tier.sync="tier"
      :spouse_dob.sync="spouse_dob"
      :childs="childs"
      @onDependentUpdate="getDependentInfo"
    />
  </div>
</template>
<script>
import DependentModal from "./DependentModal.vue";
import axios from "axios";
export default {
  name: "DependentInfomation",
  components: { DependentModal },
  data: () => ({
    spouse_dob: null,
    childs: null,
  }),
  props: {
    tier: String,
    isSubmitHidden: Boolean
  },
  mounted() {
    this.getDependentInfo();
  },
  methods: {
    getDependentInfo() {
      axios
        .get(
          `${
            process.env.VUE_APP_API_BASE
          }/get-temp-dependent-info?enrollment_id=${window.localStorage.getItem(
            "tempId"
          )}`
        )
        .then((res) => {
          let data = res.data.data;
          this.spouse_dob = data.spouse_dob?this.formatDate(data.spouse_dob):null;
          this.childs = data.no_of_children;
        });
    },
    formatDate(inputDate) {
      let date = new Date(inputDate);
      if (!isNaN(date.getTime())) {
        return (
          date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
        );
      }
    },
  },
};
</script>
