<template>
  <div class="container group-option-container">
    <div class="group-age-container">
      <div class="groups-container">
        <div class="d-flex justify-content-center">
          <div class="groups-info-img">
            <img src="../assets/images/groups.png" class="groups-select-img" />
            <div class="group-select-text">Groups</div>
          </div>
        </div>
        <div>
          <div class="row selection-container">
            <div class="col-md-12 col-lg-4 col-xl-4">
              <div class="group-selection-container" @click="routeToGroupQuote">
                <img src="../assets/images/submit-group-quote.png"  class="submit-group-img"/>
                <div class="group-text-container">Get Group Quote</div>
              </div>
            </div>
            <div class="col-md-12 col-lg-4 col-xl-4">
              <div class="group-selection-container" @click="routeToEmployee()">
                <img src="../assets/images/add-new-employee.png"
                     class="add-employee-img"/>
                <div class="group-text-container">Add New Employee</div>
              </div>
            </div>
            <div class="col-md-12 col-lg-4 col-xl-4">
              <div class="group-selection-container" @click="routeToViewPlans">
                <img src="../assets/images/view-plans.png" class="view-plan-img" />
                <div class="group-text-container">View Plans</div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button class="group-back-btn" @click="routeToLandingPage">
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GroupHomeOption",
  data () {
    return {
      groupQuoteLink: process.env.VUE_APP_GROUP_QUOTE_LINK
    }
  },
  methods: {
    routeToGroupQuote() {
      window.open(this.groupQuoteLink, '_blank');
    },
    routeToEmployee () {
      console.log('route to employee')
      let app = this
      app.$bus.$emit('renderEmployeeInfo')
    },
    routeToViewPlans () {
      let app = this
      app.$router.push('/plans')
    },
    routeToLandingPage () {
      let app = this
      app.$router.push('/')
    }
  }
}

</script>

<style scoped>
.group-age-container {
  min-height: 320px;
}
.group-option-container {
  padding:4.5rem;
}
.groups-container {
  position: relative;
  top: 5px;
  padding: 3em 2em;
}
</style>