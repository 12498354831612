<template>
  <div class="question-call" :style="{ 'background-image': 'url(' + questionCallImage + ')' }">
    <div class="container question-container" v-if="landingInfo && landingInfo.data && landingInfo.data.gcode === 'GOV8358'">
      <div class="mb-4 text-center">
        <img :src="footerLeftIcon" class="footer-left-icon-container" />
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-8 text-center">
          <h2>Questions? Call <a :href="`tel:${repLandingInfo.phone}`"><strong>{{repLandingInfo.phone}}</strong></a></h2>
          <p>We're standing by to help you make the best decision.</p>
        </div>
      </div>
    </div>
    <div class="container question-container" v-else>
     <div class="mb-4">
       <b-row no-gutters>
         <b-col cols="2" sm="2" md="2" lg="2" xl="2">
           <img :src="footerLeftIcon" class="footer-left-icon-container" />
         </b-col>
         <b-col cols="8" sm="8" md="8" lg="8" xl="8" class="question-inner-text">
           <div class="text-center">Access to the insurance plans and large group pricing on this website is predicated upon</div>
           <div class="text-center">joining Elevate Wellness, a national association with over 100,000 members.</div>
           <div class="text-center"><span class="learn-more" @click="routeToPlanDetail">Click here to Learn more</span></div>
         </b-col>
         <b-col  cols="2" sm="2" md="2" lg="2" xl="2">
           <img :src="footerRightIcon" class="footer-right-icon-container" />
         </b-col>
       </b-row>
       </div>
      <div class="row justify-content-center">
        <div class="col-xl-8 text-center">
          <h2>Questions? Call <a :href="`tel:${phone}`"><strong>{{phone}}</strong></a></h2>
          <p>We're standing by to help you make the best decision.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: 'QuestionCall',
  data () {
    return {
      questionCallImage: 'https://benefit-store-assets.s3.us-east-2.amazonaws.com/cta-bg.jpg',
      footerLeftIcon: 'https://benefit-store-assets.s3.us-east-2.amazonaws.com/footer-img-left.png',
      footerRightIcon: 'https://benefit-store-assets.s3.us-east-2.amazonaws.com/footer-img-right.png',
      planId: null,
      planName: 'elevate',
      eprocess: '',
      openType: ''
    }
  },
  props:{
    phone:{required:false,default:'8882434011'},
    groupCode: {
      type: String,
      required: false
    }
  },
  computed: {
    landingInfo () {
      return this.$store.getters.getLandingInfo
    },
    repLandingInfo () {
      return this.$store.getters.getRepInfo
    }
  },
  mounted() {
    let app = this
    app.eprocess = window.localStorage.getItem('eprocess');
    app.openType = window.localStorage.getItem('open_type');
    app.fetchCatchInfo()
  },
  methods: {
    fetchCatchInfo() {
      let siteDomain = window.btoa(unescape(encodeURIComponent(process.env.VUE_APP_SITE_DOMAIN)))
      // let siteDomain = 'aHR0cHM6Ly9lbnJvbGwucHVyZW5yb2xsLmNvbQ=='
      let url = `${process.env.VUE_APP_API_BASE}/get-external-site-dynamic-information?site_domain=${siteDomain}`
      caches.open('fetch_color_cache').then((cache) => {
        cache.match(url).then(response => {
          response.json().then(data => {
            let getCatchColor = []
            let newObj = {}
            getCatchColor = data
            getCatchColor.map(item => {
              newObj[item.key] = item.value;
            });
            this.questionCallImage = newObj["VUE_APP_CALL_BG"]
            this.footerLeftIcon = newObj["VUE_APP_FOOTER_LEFT_ICON"]
            this.footerRightIcon = newObj["VUE_APP_FOOTER_RIGHT_ICON"]
          })
        })
      }).catch((err) => {
        console.log(err);
      })
    },
    routeToPlanDetail () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-goenroll-footer-plan')
          .then(function (response) {
            if (response.data.status == 'success') {
              app.planId = response.data.data.plan_id
              window.open(`plan/${app.planId}/${app.planName}`, '_blank')
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    }
  }
}
</script>

<style lang="scss">
.question-call {
  position: relative;
  z-index: 0;
}
</style>

