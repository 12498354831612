<template>
  <div class="container">
    <div class="send-quote-container">
      <div class="name-place" v-if="sendQuoteCartInfo.quote_name">
        <span class="text-capitalize"> {{ sendQuoteCartInfo.quote_name }}, </span>
      </div>
      <h5 class="mb-2 pb-1">Welcome to your personalized quote!</h5>
      <p class="mt-1 mb-0 welcome-stat">
       
        Below , you'll find a selection of plans and pricing designed to meet
        your unique needs. <br />
        If everything in the quote looks good , please click "Accept" to enroll
        and start enjoying the benefits of your new plans(s).<br />
        You cans also browse other options (using the menu above ) to add or
        replace coverage as needed to ensure you have the perfect plan.
      </p>
    </div>
    <div class="row mb-4">
      <div class="col-xl-8 col-lg-8 col-md-12">
        <div class="row">
          <div
            class="col-lg-6 col-md-6 mb-3"
            v-for="(plan, index) in cartPlans"
            v-bind:key="index"
          >
            <div class="plan-container card-block" v-if="plan.removable == true">
              <div class="plan-block-info text-start">
                <div class="img-wrap">
                  <img :src="plan.plan_logo" alt="" />
                </div>

                <h4 class="plan-title mt-1 mb-3">
                  {{ plan.plan_name }}
                </h4>
              
                <div v-html="plan.plan_short_text" class="plan-desc"></div>
              </div>

              <div class="plan-price-container">
                <view-plan-price-detail
                  :priceInfo="plan?.tier_price"
                  :tempId="tempId"
                  v-if="tempId"
                />
                <button class="view-btn" v-on:click="nextPage(plan.plan_id)">
                  View More Details
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-12">
        <div class="fee-card mb-3">
          <div class="">
            If you have any questions or concerns, please reach out to me:
          </div>
          <div
            class="rep-info-wrap mt-3"
            v-if="repInfo && Object.keys(repInfo).length"
          >
            <div class="rep-info mb-2">
              <b>{{ repInfo.name }}</b>
              <p class="mb-0 tel-email-info"><a :href="`tel:${repInfo.phoneNumber }`">{{repInfo.phoneNumber }}</a></p>
              <p class="mb-0 tel-email-info"><a :href="`mailto:${ repInfo.email}`">{{ repInfo.email}}</a
              ></p>
            </div>
          </div>
        </div>
        <div class="fee-card amount-card" v-if="cartPlans.length > 0">
          <div class="fee-card-list">
            <div>
              <label>Effective Date</label>
              <select
                v-model="effective_date"
                @change="renderEffectiveDate"
                class="custom-input mb-3"
              >
                <option
                  v-for="eff in effectiveDateList"
                  :value="eff"
                  :key="eff"
                >
                  {{ eff }}
                </option>
              </select>
            </div>
            <div
              class="fee-list"
              v-for="(plan, index) in cartPlans"
              v-bind:key="index"
            >
              <div
                class=""
                v-if="plan.removable == false && plan.price !== null"
              >
                {{ plan.plan_name }}
              </div>
              <div v-if="plan.removable == false && plan.price !== null">
                ${{ plan.price }}
              </div>
            </div>
            <div class="fee-list">
              <div class="">First Month</div>
              <div class="price">${{ sendQuoteCartInfo.cartFirst }}</div>
            </div>
            <div class="fee-list">
              <div class="">Recurring Monthly</div>
              <div class="price">${{ sendQuoteCartInfo.cartReccur }}</div>
            </div>
          </div>

          <button class="accept-btn" @click="setEmailStatus('accept')">
            Accept and Enroll</button
          ><br />
          <button class="decline-btn" @click="setEmailStatus('decline')">Decline</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "../assets/css/common.css";
import ViewPlanPriceDetail from "../components/ViewPlanPriceDetail";
import axios from "axios";
export default {
  name: "SendQuotePage",
  components: {
    ViewPlanPriceDetail,
  },
  props: {
    cartPlans: {
      type: Array,
    },
    sendQuoteCartInfo: {
      type: Object,
    },
    effectiveDateList: {
      type: Array,
    },
    effective_date: {
      type: String,
    },
    tempId: {
      type: String,
    }
  },
  data() {
    return {
      limit: 5,
      showMore: false,
      repInfo: {},
      agentId: ''
    };
  },
  mounted () {
    let app = this
    app.agentId = window.localStorage.getItem("agent_id");
    app.renderAgentInfo();
  },
  computed: {
    visibleItems() {
      return this.showMore ? this.items : this.items.slice(0, this.limit);
    },
  },
  methods: {
    toggleShow() {
      this.showMore = !this.showMore;
    },
    renderEffectiveDate() {
      let app = this;
      app.$emit("effectiveDate", app.effective_date);
    },
    nextPage: function (pid) {
      let app = this;
      app.$emit('renderPlanDetailInfo', pid)
    },
    proceedToEnrollment() {
      let app = this;
      app.$emit("proceedToEnrollment");
    },
    routeToDecline() {
      let app = this;
      app.$router.push("/decline-reminder-email");
    },
    renderAgentInfo () {
      let app = this;
      app.landing = {};
      axios.get(process.env.VUE_APP_API_BASE + '/get-agent-landing-page-info', {
        params: {
          agent_id: app.agentId
        },
      })
      .then(function (response) {
        app.repInfo = {};
        app.repInfo['name'] = response.data.data.display_name;
        app.repInfo['email'] = response.data.data.display_email;
        app.repInfo['phoneNumber'] = response.data.data.display_phone;
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    setEmailStatus (emailStatus) {
      let app = this;
      let quoteData = {}
      quoteData['enrollment_id'] = app.tempId,
      emailStatus == 'accept' && (quoteData['quote_status'] = 1)
      emailStatus == 'decline' && (quoteData['quote_status'] = 0)
      axios.post(process.env.VUE_APP_REP_DASHBOARD_API + "/quote/update-quoteseen-status", quoteData)
          .then(function (response) {
            console.log(response);
            if (response.data.status == "success") {
              app.checkForRedirection(emailStatus)
            }
          })
          .catch(function (error) {
            console.log(error.response);
          });
    },
    checkForRedirection (emailStatus) {
      let app = this
      emailStatus == 'accept' && (app.proceedToEnrollment())
      emailStatus == 'decline' && (app.routeToDecline())
    }
  },
};
</script>
<style lang="scss" scoped>
.img-wrap img {
  max-width: 170px;
  max-height: 70px;
  margin-bottom: 1rem;
}
.plan-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border: 3px solid #75caff61;
}

.plan-block-info {
  height: 100%;
  position: relative;
  padding-top: 0.6rem;
  padding-bottom: 0rem;
}
.view-btn {
  min-width: 100%;
  position: relative;
  bottom: 0;
}

.plan-card-quote {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.plan-desc {
  padding: 1em;
}
.plan-price-container {
  position: relative;
  top: -15px;
}
.plan-desc ul{
padding-left: 0.5rem;
}

.tel-email-info {
  a {
    color: #000000;
  }
}
</style>